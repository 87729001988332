import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, prospexStore, mainStore, analyticsStore, ocorrenciaStore } from '@/store';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import CriarOcorrenciaComponent from '@/components/CriarOcorrenciaComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
let ProspexList = class ProspexList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Data da criação',
                table: 'prospex',
                value: 'created_at',
                show: true,
            },
            {
                text: 'Nome',
                table: 'prospex',
                value: 'nome',
                type: 'str',
                show: true,
            },
            {
                text: 'Idade',
                table: 'prospex',
                value: 'idade',
                type: '',
                show: true,
            },
            {
                text: 'Curso de interesse',
                table: 'prospex',
                value: 'curso_interesse',
                type: '',
                show: true,
            },
            {
                text: 'Possivel curso',
                table: 'tmp',
                value: 'curso_possivel',
                type: '',
                show: true,
            },
            {
                text: 'Dias de preferência',
                table: 'prospex',
                value: 'dias_preferencia',
                type: '',
                show: true,
            },
            {
                text: 'Período de preferência',
                value: 'periodo_preferencia',
                table: 'prospex',
                type: '',
                show: true,
            },
            {
                text: 'Celular',
                value: 'celular',
                table: 'prospex',
                type: 'str',
                show: true,
            },
            {
                table: 'primeiro_atendimento_usuario',
                text: 'Primeiro atendimento',
                type: 'str',
                show: true,
                value: 'primeiro_atendimento_full_name',
                field: 'full_name',
            },
            {
                text: 'Data do primeiro atendimento',
                value: 'created_at',
                table: 'prospex',
                type: '',
                show: true,
            },
            {
                table: 'segundo_atendimento_usuario',
                text: 'Segundo atendimento',
                type: 'str',
                show: true,
                value: 'segundo_atendimento_full_name',
                field: 'full_name',
            },
            {
                text: 'Status',
                value: 'status_id',
                table: 'prospex',
                type: '',
                show: true,
            },
            {
                text: 'Motivo de perda',
                value: 'motivo_perda_id',
                table: 'prospex',
                type: '',
                show: true,
            },
        ];
        this.ocorrenciasHeaders = [
            {
                text: 'Data',
                value: 'created_at',
                table: 'ocorrencia',
                show: true,
                type: 'date',
            },
            {
                text: 'Criado por',
                table: 'ocorrencia',
                value: 'created_by_id',
                show: true,
            },
            {
                text: 'Departamento',
                table: 'ocorrencia',
                value: 'departamento_id',
                show: true,
            },
            {
                text: 'Contato',
                table: 'objecao',
                value: 'objecao_nome',
                field: 'nome',
                show: true,
            },
            {
                text: 'Assunto',
                table: 'objecao',
                value: 'assunto',
                show: true,
            },
            {
                text: 'Observação',
                table: 'ocorrencia',
                value: 'observacao',
                type: 'str',
                show: true,
            },
        ];
        this.dialogCriarOcorrencia = false;
        this.entidadeIdOcorrenciaAtual = null;
        this.entidadeTipoOcorrenciaAtual = '';
        this.ocorrenciasItems = [];
        this.loadingOcorrencias = false;
        this.carregandoTotals = false;
        this.selectPeriodDate = null;
        this.loadTotals = false;
        this.loading = false;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Prospex) {
            return userToolStore.userToolsIndexed.Prospex[0].allow_create;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Prospex) {
            return userToolStore.userToolsIndexed.Prospex[0].allow_delete;
        }
    }
    async buscarTotals() {
        this.carregandoTotals = true;
        await this.doSearchTotals();
        this.carregandoTotals = false;
        this.loadTotals = true;
    }
    async onCancel() {
        this.dialogCriarOcorrencia = false;
    }
    async showOcorrencias(item) {
        if (item.value == true) {
            this.loadingOcorrencias = true;
            this.ocorrenciasItems = await ocorrenciaStore.getMultiOcorrencias({
                types: ['prospex'],
                entidade_id: item.item.id
            });
            this.entidadeIdOcorrenciaAtual = item.item.id;
            this.entidadeTipoOcorrenciaAtual = 'prospex';
            this.loadingOcorrencias = false;
        }
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'prospex.created_at': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        });
        // ficava aqui quando carregava na abertura da tela
        if (this.loadTotals) {
            await this.doSearchTotals();
        }
        await analyticsStore.executeSearchItems();
        // @ts-ignore
        await this.$refs.loadingButton.changeLoadingStatus(false);
    }
    async doSearchItems(queryIn) {
        return await prospexStore.getProspexs(queryIn);
    }
    async doSearchTotals() {
        return await analyticsStore.getAnalyticsProspex({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(prospexStore.getProspexs);
        analyticsStore.setHeaders(this.headers);
        // tela que nao tem defaultFilter precisa ter o buscar() aqui
        // this.buscar();
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-prospexs-edit', params: { id } });
    }
};
ProspexList = __decorate([
    Component({
        components: {
            ClickableCard,
            OcorrenciaComponent,
            OpenNewTabComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
            CriarOcorrenciaComponent,
            PeriodSelectorComponent,
            SaveButton
        },
    })
], ProspexList);
export default ProspexList;
